
import { defineComponent } from 'vue'
import StyledFooter from '@/styled-components/components/SFSFooter'

export default defineComponent({
  name: 'SFSFooter',
  components: {
    StyledFooter
  },
  props: {
    version: {
      type: String,
      default: 'V8.39'
    }
  }
})
